<template>
    <div>
        <h2 class="section-header">
            <img src="@/assets/images/withdraw.svg" alt="withdraw"/>    
            Withdraw from Wallet
        </h2>
        <div class="withdraw-content">
            <div class="cash-balance">
                <p>Available Balance</p>
                <p class="balance-figure">{{formattedBalance(availableBalance)}}</p>

                <div class="bank-card">
                    <div class="details">
                        <p>{{bankName || "BANK NAME"}}</p>
                        <p>{{bankAccount || "ACCOUNT NUMBER"}}</p>
                        <p>{{accountName || "ACCOUNT NAME"}}</p>
                    </div>
                    <div class="image-container">
                        <!-- this condition is made under the assumption that the passportUrl is null -->
                        <img :src="passportUrl" alt="your profile picture" class="passport" v-if="passportUrl">
                        <img src="@/assets/images/user-profile.jpg" alt="your profile picture" class="passport" v-else>
                    </div>
                </div>

                <p>Amount (&#8358;)</p>
                <input type="text" class="withdraw-input" v-model="theWithdrawalAmount">

                <div class="resulting-amount">
                    <div class="withdrawing">
                        <p>Withdrawing</p>
                        <p>&#8358;{{withdrawalAmount || "0.00"}}</p>
                    </div>

                    <div class="new-balance">
                        <p>New Balance</p>
                        <p>{{remainingBalance}}</p>
                    </div>
                </div>

                <button class="withdraw-btn" @click="withdraw()">WITHDRAW</button>
            </div>

            <div class="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Wallet Transactions</th>
                            <th>Amount&nbsp;(&#8358;)</th>
                            <th>Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <!-- when they apply to withdraw but it has not been approved yet, status is 0 -->
                    <!-- when they apply to withdraw and it has been approved, status is 1 -->
                    <tbody>
                        <tr v-for="withdrawal in withdrawalLog" :key="withdrawal.id">
                            <td class="capitalize">{{withdrawal.description}}</td>
                            <td>{{toTwoDecimal(withdrawal.amount)}}</td>
                            <td>{{formatDate(withdrawal.created_at)}}</td>
                            <td><span 
                            :class="[withdrawal.readystatus == 0 ? 'failed': '',
                             withdrawal.readystatus == 1 ? 'success': '']"></span></td>
                        </tr>
                    </tbody>
                </table>
                <div class="empty" v-if="withdrawalLog.length === 0 && fetchData === 'loaded'">No Records</div>
                <div class="loading-container" v-if="fetchData === 'loading'"><Loader/></div>
            </div>
        </div>
    </div>
</template>

<script>
import CF from '../helpers/currencyFormatter';
import transactions from '../services/transactions';
import formatDate from '../helpers/extractDate';

import Loader from './Loader';

export default {
    name: "WithDraw",
    components: {
        Loader,
    },
    data() {
        return {
            userID: null,
            availableBalance: "",
            withdrawalAmount: "",
            remainingBalance: "",

            bankName: "",
            bankAccount: "",
            accountName: "",
            passportUrl: "",

            withdrawalLog: [],
            fetchData: "",
        }
    },
    computed: {
        theWithdrawalAmount: {
            get() {
                return this.withdrawalAmount;
            },
            set(value) {
                let amount = value.split(",").join("");
                this.estimateRemainingBalance(amount);

                let decimalArray = amount.split(".");
                if (decimalArray.length === 2) {
                    amount = decimalArray[0];
                    amount = (+amount).toLocaleString();
                    this.withdrawalAmount = amount + "." + decimalArray[1];
                } else {
                    this.withdrawalAmount = (+amount).toLocaleString();
                }
            }
        }
    },
    methods: {
        formattedBalance(amount) {
            return CF.naira.format(amount);
        },
        toTwoDecimal(amount) {
            return CF.toTwoDecimal(amount);
        },
        formatDate(date) {
            return formatDate(date);
        },
        estimateRemainingBalance(amount) {
            let newBalance = this.availableBalance - amount;
            this.remainingBalance = this.formattedBalance(newBalance);
        },
        withdraw() {
            let withdrawAmount = this.withdrawalAmount.split(",").join("");
            if (withdrawAmount == 0) {
                return this.$toast.error("Please enter an amount");
            }
            if (+this.availableBalance < +withdrawAmount) {
                return this.$toast.error("Insufficient balance");
            }
            
            let userId = (this.userID).toString();
            let data = JSON.stringify({
                userid: userId,
                amount: withdrawAmount
            });
            transactions.applyForWithdrawal(data)
            .then(data => {
                if (data.success) {
                    this.$toast.success(data.message);
                    let newData = data.data;
                    newData.readystatus = "0";
                    this.withdrawalLog.unshift(newData);
                    this.withdrawalAmount = null;
                    this.remainingBalance = this.formattedBalance(this.availableBalance);
                } else {
                    this.$toast.error(data.error);
                }
            })
            .catch((err) => {
                console.log(err);
                // this.$toast.error("please check your network and refresh the page");
            })
        }
    },
    created() {
        const user = this.$store.getters.getUser;
        this.userID = user.id;

        this.bankName = user.bankname;
        this.bankAccount = user.accountnumber;
        this.accountName = user.accountname;
        this.passportUrl = user.passportfile;

        // initialize remaining balance
        this.remainingBalance = this.formattedBalance(this.availableBalance);

        this.fetchData = 'loading';
        transactions.walletDebitDetails(this.userID)
        .then(data => {
            if (data.success) {
                this.availableBalance = data.available;
                this.withdrawalLog = data.debitarray;
            } else {
                this.$toast.error(data.error);
            }
        })
        .catch((err) => {
            console.log(err);
            // this.$toast.error("please check your network and refresh the page");
        })
        .finally(() => {
            this.fetchData = 'loaded';
        })
    }
}
</script>

<style scoped>
@import "../styles/section-header.css";
@import "../styles/table.css";

    .section-header {
        align-items: flex-end;
        margin-bottom: 14px;
    }

    .withdraw-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .cash-balance {
        background: #fff;
        height: fit-content;
        width: 46%;
        min-width: 450px;
        margin-right: 10px;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 50px 32px 27px;
    }

    .table-container {
        width: 45%;
        min-width: 456px;
        margin-top: 0;
        height: fit-content;
    }

    .cash-balance p {
        font-weight: 600;
        font-size: 18px;
    }

    p.balance-figure {
        font-size: 36px;
        line-height: 1.7;
        color: #2FC643;
        font-family: "Gotham-Book";
        font-weight: 600;
    }

    .bank-card {
        background: #2FC643;
        border-radius: 5px;
        width: 100%;
        height: fit-content;
        padding: 50px 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 30px 0 63px;
    }

    .bank-card .details {
        height: 119.5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .bank-card p {
        font-size: 16px;
        text-transform: uppercase;
        color: #FFFFFF;
    }

    .passport {
        border-radius: 50%;
        object-fit: cover;
        width: 119.5px;
    }

    .withdraw-input {
        background: rgba(74, 186, 65, 0.03);
        border: 1px solid #2FC643;
        border-radius: 5px;
        width: 100%;
        height: 46px;
        margin: 10px 0 22px;
        padding: 0 10px;
        font-family: "Gotham-Book";
    }

    .resulting-amount {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .withdrawing p:first-of-type, .new-balance p:first-of-type {
        font-size: 12px;
        line-height: 1.0;
    }

    .withdrawing p:last-of-type, .new-balance p:last-of-type {
        font-size: 18px;
        line-height: 1.2;
        font-family: "Gotham-Book";
        font-weight: 600;
    }

    .withdrawing p:last-of-type {
        color: #EA0000;
    }

    .new-balance p:last-of-type {
        color: #2FC643;
    }

    .withdraw-btn {
        margin-top: 38px;
        background: #2FC643;
        color: #fff;
        width: 100%;
        border-radius: 5px;
        height: 46px;
    }

    tr td:last-of-type, tr th:last-of-type {
        text-align: center;
        min-width: 80px;
    }

    @media (max-width: 1249.99px) {
        .cash-balance {
            width: 100%;
            min-width: unset;
            max-width: 600px;
            margin-right: 0;
            /* padding: 50px 32px 27px; */
        }

        .table-container {
            margin-top: 50px;
            width: 100%;
            min-width: unset;
            max-width: 600px;
        }
    }

    @media (max-width: 575.98px) {
        .cash-balance {
            padding: 30px 25px;
        }
        .cash-balance p {
            font-weight: 600;
            font-size: 16px;
        }
        p.balance-figure {
            font-size: 28px;
        }
        .bank-card {
            padding: 25px 15px;
        }
        .bank-card p {
            font-size: 14px;
        }
    }
    @media (max-width: 424.98px) {
        .bank-card {
            flex-direction: column;
            align-items: flex-start;
        }
        .bank-card .image-container {
            margin: 20px auto 0;
        }
    }
</style>